import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function TwitchIcon({ className }: { className?: string }) {
  return <svg
    className={`${className} mr-2`}
    width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M43.999 24.627L36.001 32.202H27.999L20.9973 38.8333V32.202H11.999V3.78933H43.999V24.627ZM9.99855 0L0 9.46965V43.5663H11.999V53.0359L21.9976 43.5663H29.9995L48 26.518V0H9.99855ZM38.0015 10.719H34.0004V22.0823H38.0015V10.719ZM23.0025 10.6722H27.0036V22.037H23.0025V10.6722Z" fill="white"></path>
  </svg>
}

export function TwitchLoginLink({
  href,
  disabled = false,
  label = 'Utiliser twitch',
  className = '?',
  children,
  ...props

}: { label?: string, disabled?: boolean } & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) {
  return <a
    href={href}
    className={className.replace('?', `${disabled ? 'btn-disabled' : ''} 
    btn-block no-animation transition btn 
    dark:text-[#9146FF] hover:text-primary-content focus:text-primary-content hover:bg-[#9146FF] focus:bg-[#9146FF] 
    dark:hover:text-white
    dark:focus:text-white
    flex-nowrap`)}
    {...props}
  >
    <FontAwesomeIcon
      size="2x"
      className="mr-2"
      icon={['fab', 'twitch']}
    />
    {children ?? label}
  </a>
}